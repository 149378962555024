import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LocationPage = () => {
  const [location, setLocation] = useState({ lat: null, lng: null, countryCode: null });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // New loading state

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        setLocation(prevState => ({ ...prevState, lat: latitude, lng: longitude }));

        setIsLoading(true); // Set loading state to true before making the API call

        axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDF1f-KBbMKXnztM3-DTr8LKjdE3cXK8AU`)
          .then(response => {
            console.log(response.data);
            const country = response.data.results[0].address_components.find(component => component.types.includes('country'));
            console.log(`Country code: ${country.short_name}`);
            setLocation(prevState => ({ ...prevState, countryCode: country.short_name }));
          })
          .catch(error => {
            console.error(error);
            setError(error.toString());
          })
          .finally(() => {
            setIsLoading(false); // Set loading state to false after the API call is complete
          });
      },
      error => {
        console.error(error);
        setError(error.message);
      }
    );
  }, []);

  return (
    <div>
      {isLoading && <div>Loading...</div>} {/* Display loading message when isLoading is true */}
      {error && <div>Error: {error}</div>}
      {location.lat && <div>Latitude: {location.lat}</div>}
      {location.lng && <div>Longitude: {location.lng}</div>}
      {location.countryCode && <div>Country code: {location.countryCode}</div>}
    </div>
  );
};

export default LocationPage;