import React, { useState, useEffect } from 'react';

const AlternativeLocationPage = () => {
  const [location, setLocation] = useState({ lat: null, lng: null, countryCode: null });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // New loading state

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        setIsLoading(true); // Set loading state to true before fetching the location

        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const { latitude, longitude } = position.coords;
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        setLocation(prevState => ({ ...prevState, lat: latitude, lng: longitude }));

        // Fetch country code using a different API or method
        const countryCode = await fetchCountryCode(latitude, longitude);
        console.log(`Country code: ${countryCode}`);
        setLocation(prevState => ({ ...prevState, countryCode }));

        setIsLoading(false); // Set loading state to false after fetching the location
      } catch (error) {
        console.error(error);
        setError(error.message);
        setIsLoading(false); // Set loading state to false in case of error
      }
    };

    fetchLocation();
  }, []);

  const fetchCountryCode = async (latitude, longitude) => {
    // Implement your logic to fetch the country code using a different API or method
    // For example, you can use a reverse geocoding API like OpenCageData or Mapbox
    // Here's an example using OpenCageData API:
    const apiKey = 'c1e77d1983734afbaf115390b1e236fc';
    const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      const country = data.results[0].components.country_code;
      return country;
    } catch (error) {
      console.error(error);
      throw new Error('Failed to fetch country code');
    }
  };

  return (
    <div>
      {isLoading && <div>Loading...</div>} {/* Display loading message when isLoading is true */}
      {error && <div>Error: {error}</div>}
      {location.lat && <div>Latitude: {location.lat}</div>}
      {location.lng && <div>Longitude: {location.lng}</div>}
      {location.countryCode && <div>Country code: {location.countryCode}</div>}
    </div>
  );
};

export default AlternativeLocationPage;